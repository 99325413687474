import { StyledEngineProvider } from "@mui/material";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import ProductTokenApiClient from "@/api/client/entities/product-token";
import baseEnvironmentConfig from "@/api/constants";
import { getFacilityData } from "@/api/facilities";
import Loader from "@/components/Loader";
import TextWithRollingList from "@/components/TextWithRollingList";
import {
  getClubStatus,
  getCurrencyCode,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer";
import {
  ACDL_JOIN_FLOW_TRACKING,
  JOIN_FLOW_MEMBERSHIP_TYPES,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import {
  ACDL_LEAD_FLOW_TRACKING,
  LEAD_FLOW_CATEGORY_TYPES,
  LEAD_FLOW_STEP_NAMES,
  LEAD_FLOW_TYPES,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { COOKIES, COUNTRIES, LOCAL_STORAGE_KEYS, PROSPECT } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { Confirmation } from "@/features/join-flow";
import {
  AppointmentConfirmation,
  LeadFlowApi,
  LeadFlowConstants,
  LeadFlowUtils,
  PersonalizeQuiz,
  ScheduleAppointment,
} from "@/features/lead-flow";
import {
  ClubSelectorPanel,
  ExitFlowConfirmation,
  Headline,
} from "@/features/user-flow";
import useActivityCount from "@/hooks/useActivityCount";
import dynamicContentReplacer from "@/utils/helpers/dynamic_content_replacer";
import FormattingUtils from "@/utils/helpers/formatting";
import { createItem } from "@/utils/helpers/localStorage";
import getFilteredPlans from "@/utils/membership-plans/getFilteredPlans";

import CorpJoinFlowApi from "../api";
import { STEPS } from "../constants";
import {
  getStoredState,
  replaceDynamicContentOfPlan,
  updateStoredState,
} from "../utils";
import ChooseMembershipPlan from "./ChooseMembershipPlan";
import styles from "./CorpJoinFlow.module.scss";
import CorpJoinForm from "./CorpJoinForm";
import EmailCodeConfirmation from "./EmailCodeConfirmation";

const CorpJoinFlow = ({ config, confirmationStep, data }) => {
  const { decrementActivityCount, incrementActivityCount, isActive } =
    useActivityCount();
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const router = useRouter();

  const [acdlInfo, setAcdlInfo] = useState();
  const [corpFormDetails, setCorpFormDetails] = useState(getStoredState());
  const [errorMessage, setErrorMessage] = useState("");
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [showClubSelector, setShowClubSelector] = useState(false);
  const [visitAppointments, setVisitAppointments] = useState([]);

  const [currentStep, setCurrentStep] = useState(corpFormDetails.step);
  const [membershipDetails, setMembershipDetails] = useState({
    buyerInfo: {
      firstName: corpFormDetails?.contactInput?.["First Name"] || "",
      lastName: corpFormDetails?.contactInput?.["Last Name"] || "",
      email: corpFormDetails?.contactInput?.["Company Email"] || "",
    },
  });
  const [selectedAppointment, setSelectedAppointment] = useState(
    corpFormDetails.submittedAppointment
  );
  const [selectedClub, setSelectedClub] = useState(
    corpFormDetails.selectedFacility
  );

  const { leadTokenId, leadTransactionId } = corpFormDetails || {};

  let headlineData;
  let headlineDataTitle;

  switch (currentStep) {
    case STEPS.contactInformation:
      headlineData = data?.headlines?.[0];
      headlineDataTitle = (
        <TextWithRollingList
          list={config.headline?.list}
          startText={config.headline?.text}
        />
      );
      break;
    case STEPS.emailCodeConfirmation:
      headlineData = data?.headlines?.[1];
      headlineDataTitle = headlineData?.fields.title;
      break;
    case STEPS.chooseMembershipPlan:
      headlineData = data?.headlines?.[1];
      headlineDataTitle = headlineData?.fields.alternateTitle;
      break;
    case STEPS.scheduleAppointment:
      headlineData = data?.headlines?.[2];
      headlineDataTitle = headlineData?.fields.title;
      break;
    case STEPS.appointmentConfirmation:
      headlineData = data?.headlines?.[2];
      headlineDataTitle = headlineData?.fields.alternateTitle;
      break;
  }

  const paymentAppHeadline = data?.headlines?.filter((h) =>
    h.fields.nonUiTitle.toLowerCase().includes("payment")
  );

  /**
   *
   */
  const getMembershipPlansInfo = useCallback(
    async (facility, companyEmail, corporateAccountId, corpFormDetails) => {
      incrementActivityCount();

      const nextCorpFormDetails = {
        ...corpFormDetails,
        selectedFacility: facility,
        selectedMembershipPlan: null,
        outreachCode: corpFormDetails?.companyName
          ? data?.marketingCode
          : corpFormDetails.outreachCode,
      };

      try {
        const { corporateAgreementId, plans, region } = await getFilteredPlans(
          facility.facilityId,
          {
            companyEmail,
            corporateAccountId,
          }
        );

        if (corporateAgreementId && plans) {
          plans?.forEach((plan) => {
            plan.description = replaceDynamicContentOfPlan(
              plan.description,
              facility
            );
          });

          nextCorpFormDetails.selectedFacility.region = region;
          nextCorpFormDetails.corporateAgreementId = corporateAgreementId;

          if (plans.length === 1) {
            nextCorpFormDetails.selectedMembershipPlan = plans[0];
          }

          setMembershipPlans(plans);
        }
      } catch (error) {
        console.error("getMembershipPlans", error);
      }

      decrementActivityCount();

      return nextCorpFormDetails;
    },
    [data?.marketingCode, decrementActivityCount, incrementActivityCount]
  );

  /**
   *
   */
  const getVisitAppointments = useCallback(
    async (facilityId) => {
      if (facilityId) {
        incrementActivityCount();

        const appointments =
          await LeadFlowApi.getOpenLeadTimesFromFacilityId(facilityId);
        setVisitAppointments(appointments);

        decrementActivityCount();
      } else {
        setVisitAppointments([]);
      }
    },
    [decrementActivityCount, incrementActivityCount]
  );

  /**
   * All these cookies are required by payment
   * app to function correctly
   */
  const setPaymentCookies = async (data) => {
    if (paymentAppHeadline?.length > 0) {
      const paymentAppHeadlineImage = paymentAppHeadline[0].fields?.image;
      data.paymentAppHeadline = {
        image: {
          altText: paymentAppHeadlineImage.fields?.altText,
          defaultImage: paymentAppHeadlineImage.fields?.defaultImage?.fields,
          lgImage: paymentAppHeadlineImage.fields?.lgImage?.fields,
        },
        subcopy: paymentAppHeadline[0].fields?.subcopy || "",
        title: paymentAppHeadline[0].fields?.title || "",
      };
    }

    const productToken = await ProductTokenApiClient.generateProductToken(data);
    const cookieOptions = { maxAge: 1200 };

    setCookie(
      baseEnvironmentConfig.session.cookies.paymentAppReferrer,
      `${window.location.origin}/join/corp`,
      cookieOptions
    );

    setCookie(
      baseEnvironmentConfig.session.cookies.paymentAppSource,
      "join",
      cookieOptions
    );
    setCookie(
      baseEnvironmentConfig.session.cookies.paymentAppProductToken,
      productToken,
      cookieOptions
    );
  };

  /**
   *
   */
  const handleChooseMembershipSubmit = async (selectedPlan) => {
    incrementActivityCount();

    const { planId, planType, cost, description } = selectedPlan;

    const descriptionReplaced = dynamicContentReplacer(description, {
      "{{CLUB_NAME}}": selectedClub.webName,
      "{{REGION}}": selectedClub.region,
    });

    const tokenPayload = {
      isCorp: true,
      companyName: corpFormDetails.companyName,
      corporateAccountId: corpFormDetails.corporateAccountId,
      corporateAgreementId: corpFormDetails.corporateAgreementId,
      corporateContactId: corpFormDetails.corporateContactId,
      productBase: {
        buyer: {
          firstName: corpFormDetails.contactInput["First Name"],
          lastName: corpFormDetails.contactInput["Last Name"],
          email: corpFormDetails.contactInput["Personal Email"],
          companyEmail: corpFormDetails.contactInput["Company Email"],
          phone: corpFormDetails.contactInput["Phone Number"],
          newsletter: corpFormDetails.receiveTextAlerts,
        },
        isUserLoggedIn: false,
        productType: "join",
      },
      selectedClubDetails: corpFormDetails.selectedFacility,
      selectedPlan: {
        planId,
        planType,
        cost,
        title: descriptionReplaced,
      },
      hasOptedOutOfEmail: !corpFormDetails.receiveTextAlerts,
      companyEmail: corpFormDetails.contactInput["Company Email"],
    };

    await setPaymentCookies(tokenPayload);

    const ACDLMembershipSelectedInfo =
      ACDL_JOIN_FLOW_TRACKING.joinFlowMembershipSelected(
        `${JOIN_FLOW_MEMBERSHIP_TYPES.SINGLE} | ${descriptionReplaced}`.toLowerCase(),
        `${cost?.monthlyFee}`,
        getCurrencyCode(corpFormDetails.selectedFacility?.country),
        corpFormDetails.selectedFacility?.facilityId
      );
    setNewDataLayer(ACDLMembershipSelectedInfo);

    updateStoredState({
      ...corpFormDetails,
      step: STEPS.confirmation,
    });

    window.location.href = process.env.NEXT_PUBLIC_PAYMENT_APP_URL;

    // redirecting, so leave it loading...
    // decrementActivityCount();
  };

  /**
   *
   */
  const handleClubEdit = () => {
    setShowClubSelector(true);
  };

  /**
   *
   */
  const handleEditAppointment = () => {
    setCurrentStep(STEPS.scheduleAppointment);
  };

  /**
   *
   */
  const handleQuizCtaClick = async (nextPersonalizeOptionsStep) => {
    incrementActivityCount();

    await CorpJoinFlowApi.updateLead({
      leadTokenId,
      facilityId: corpFormDetails.selectedFacility?.facilityId,
      answers: corpFormDetails.personalizeOptions,
    });

    const nextCorpFormDetails = {
      ...corpFormDetails,
      personalizeOptionsStep: nextPersonalizeOptionsStep,
    };

    setCorpFormDetails(nextCorpFormDetails);
    updateStoredState(nextCorpFormDetails);

    decrementActivityCount();
  };

  /**
   *
   */
  const handleQuizItemClick = (
    personalizeOptions,
    personalizeOptionsSelectedPerStep
  ) => {
    const nextCorpFormDetails = {
      ...corpFormDetails,
      personalizeOptions,
      personalizeOptionsSelectedPerStep,
    };

    setCorpFormDetails(nextCorpFormDetails);
    updateStoredState(nextCorpFormDetails);
  };

  /**
   *
   */
  const handleScheduleAppointment = async (selectedAppointment) => {
    setSelectedAppointment(selectedAppointment);
    const selectedVisitTime = selectedAppointment;
    const selectedVisitHour = selectedAppointment.split("T")[1];

    const nextCorpFormDetails = {
      ...corpFormDetails,
      scheduledVisitTime: selectedVisitTime,
      scheduledVisitHour: selectedVisitHour,
      scheduledFacility: corpFormDetails.selectedFacility,
      submittedAppointment: selectedAppointment,
    };

    incrementActivityCount();

    if (corpFormDetails.isReferralFlow) {
      await CorpJoinFlowApi.updateLead({
        leadTokenId: corpFormDetails.leadTokenId,
        facilityId: corpFormDetails.selectedFacility?.facilityId,
        answers: corpFormDetails.answers,
        scheduledVisitTime: selectedVisitTime,
      });

      // ACDL Confirm A Visit Event
      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedVisitTime,
          selectedVisitHour,
          !selectedVisitHour
        )
      );
    } else if (corpFormDetails.submittedAppointment) {
      // ACDL Confirm A Visit Event
      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedVisitTime,
          selectedVisitHour,
          !selectedVisitHour
        )
      );

      const results = await CorpJoinFlowApi.updateLead({
        ...corpFormDetails,
        scheduledVisitTime: selectedVisitTime,
        facilityId: corpFormDetails.selectedFacility?.facilityId,
      });

      if (!results) {
        alert("An error has occurred.");
      }
    } else {
      const leadTokenId = await CorpJoinFlowApi.updateAbandonedLead({
        ...corpFormDetails,
        meetingPurpose: PROSPECT.MEETING_PURPOSES.TOUR,
        scheduledVisitTime: selectedVisitTime,
      });

      nextCorpFormDetails.leadTokenId = leadTokenId;

      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedVisitTime,
          selectedVisitHour,
          !selectedVisitHour
        )
      );
    }

    setCorpFormDetails(nextCorpFormDetails);

    updateStoredState({
      ...nextCorpFormDetails,
      step: STEPS.appointmentConfirmation,
    });

    setCurrentStep(STEPS.appointmentConfirmation);

    decrementActivityCount();
  };

  /**
   *
   */
  const handleSelectedClub = async (club) => {
    if (club) {
      incrementActivityCount();

      const clubInfo = await getFacilityData(club.facilityId);

      setNewDataLayer({
        event: "lead-flow",
        leadFlow: {
          step: LEAD_FLOW_STEP_NAMES.CLUB_SELECTION,
          clubName: club.name?.toLowerCase(),
          facilityID: club.facilityId,
          clubID: clubInfo?.clubId?.toString(),
          clubRegion: clubInfo?.region,
          ...(clubInfo?.status && {
            clubStatus: getClubStatus(clubInfo.status),
          }),
          clubType: clubInfo?.clubType?.toLowerCase(),
        },
      });

      club.clubId = clubInfo?.clubId;

      if (currentStep === STEPS.chooseMembershipPlan) {
        const nextCorpFormDetails = await getMembershipPlansInfo(
          clubInfo,
          corpFormDetails.contactInput["Company Email"],
          corpFormDetails?.corporateAccountId,
          corpFormDetails
        );

        setCorpFormDetails(nextCorpFormDetails);
        updateStoredState(nextCorpFormDetails);
      }

      decrementActivityCount();
    }

    setSelectedClub(club);
  };

  /**
   *
   */
  const handleShowScheduleVisit = async () => {
    setNewDataLayer({
      event: "lead-flow",
      leadFlow: {
        step: LEAD_FLOW_STEP_NAMES.SUBMIT,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
        category: LEAD_FLOW_CATEGORY_TYPES.ENGAGE,
        clubName: corpFormDetails.selectedFacility.webName?.toLowerCase(),
        facilityID: corpFormDetails.selectedFacility.facilityId,
        clubID: corpFormDetails.selectedFacility.clubId?.toString(),
        activationCode: data?.marketingCode
          ? data?.marketingCode
          : "CORPONLINE_ENROLL",
        ...(leadTransactionId && { leadTransactionId }),
        ...(corpFormDetails?.contactInput?.["Personal Email"] && {
          hashedEmail: FormattingUtils.encode(
            corpFormDetails.contactInput["Personal Email"]
          ),
        }),
      },
    });

    incrementActivityCount();

    const isAcceptingAppointments = LeadFlowUtils.isClubAcceptingAppointments(
      corpFormDetails.selectedFacility,
      data.config
    );

    const nextCorpFormDetails = {
      ...corpFormDetails,
    };

    if (!isAcceptingAppointments) {
      const leadTokenId =
        await CorpJoinFlowApi.updateAbandonedLead(corpFormDetails);

      nextCorpFormDetails.leadTokenId = leadTokenId;
      nextCorpFormDetails.step = STEPS.appointmentConfirmation;
    } else {
      getVisitAppointments(corpFormDetails.selectedFacility?.facilityId);
      nextCorpFormDetails.step = STEPS.scheduleAppointment;
    }

    setCorpFormDetails(nextCorpFormDetails);
    updateStoredState(nextCorpFormDetails);

    setCurrentStep(nextCorpFormDetails.step);

    decrementActivityCount();
  };

  /**
   *
   */
  const handleSkipAppointment = async () => {
    incrementActivityCount();

    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.confirmAVisit("Skip selecting date", null, !null)
    );

    const nextCorpFormDetails = {
      ...corpFormDetails,
    };

    if (!selectedAppointment) {
      const leadTokenId =
        await CorpJoinFlowApi.updateAbandonedLead(nextCorpFormDetails);
      nextCorpFormDetails.leadTokenId = leadTokenId;
    }

    setCorpFormDetails(nextCorpFormDetails);

    setCurrentStep(STEPS.appointmentConfirmation);

    decrementActivityCount();
  };

  /**
   *
   */
  const handleSubmitCorpJoinForm = async (formValues) => {
    incrementActivityCount();

    const eqxCurrentUrl = sessionStorage.getItem("eqxCurrentUrl");
    const eqxPreviousUrl = sessionStorage.getItem("eqxPreviousUrl");

    const nextCorpFormDetails = {
      ...corpFormDetails,
      contactInput: {
        ...formValues,
      },
      receiveTextAlerts: formValues?.receiveTextAlerts,
      selectedFacility: formValues?.selectedClub,
      suggestedFacility: null,
      scheduledFacility: null,
      selectedAppointment: null,
      hasCreatedCorp: null,
      leadTransactionId: "",
      sourceUrl: eqxCurrentUrl,
      ctaLabel: "Get Started",
      outreachCode: data?.marketingCode
        ? data?.marketingCode
        : "CORPONLINE_ENROLL",
      referringUrl: eqxPreviousUrl,
      referringCtaLabel: "Get Started",
      isCorpLead: true,
    };

    const verifyEmailResponse = await CorpJoinFlowApi.verifyValidCorpEmail(
      nextCorpFormDetails.contactInput["Company Email"],
      nextCorpFormDetails.selectedFacility.facilityId
    );
    const isValidCorpEmail = verifyEmailResponse?.domainVerified;

    if (isValidCorpEmail) {
      const apiResult = await CorpJoinFlowApi.createNewCorp(
        nextCorpFormDetails,
        setNewDataLayer
      );

      if (apiResult?.status === 200) {
        nextCorpFormDetails.leadTransactionId = apiResult.TransactionId;
        nextCorpFormDetails.createdDate = new Date().getTime();

        const emailCodeVerifyApiResult =
          await CorpJoinFlowApi.createEmailCode(nextCorpFormDetails);

        if (emailCodeVerifyApiResult.status === 200) {
          nextCorpFormDetails.step = STEPS.emailCodeConfirmation;
          nextCorpFormDetails.corporateAccountId =
            emailCodeVerifyApiResult.data.corporateAccountId;
          nextCorpFormDetails.corporateContactId =
            emailCodeVerifyApiResult.data.corporateContactId;
          nextCorpFormDetails.companyName = emailCodeVerifyApiResult.data.name;

          const nextCorpFormDetailsWithPlans = await getMembershipPlansInfo(
            nextCorpFormDetails?.selectedFacility,
            nextCorpFormDetails.contactInput["Company Email"],
            nextCorpFormDetails?.corporateAccountId,
            nextCorpFormDetails
          );

          setCorpFormDetails(nextCorpFormDetailsWithPlans);
          updateStoredState(nextCorpFormDetailsWithPlans);

          setCurrentStep(nextCorpFormDetails.step);
        }
      } else {
        setErrorMessage(config.SameEmailErrorMessage);
      }

      decrementActivityCount();
    } else {
      nextCorpFormDetails.outreachCode = data?.joinMarketingCode
        ? data?.joinMarketingCode
        : nextCorpFormDetails.outreachCode + "_JOIN";
      nextCorpFormDetails.contactInput["Email Address"] =
        nextCorpFormDetails.contactInput["Personal Email"];
      nextCorpFormDetails.contactInput["Company Email (optional)"] =
        nextCorpFormDetails.contactInput["Company Email"];

      const newState = {
        ...nextCorpFormDetails,
        showNonEligibleBanner: true,
        selectedFacility: formValues?.selectedClub,
        outreachCode: nextCorpFormDetails.outreachCode,
        receiveTextAlerts: formValues?.receiveTextAlerts,
        subscribeToNewsletter: formValues?.subscribeToNewsletter,
      };

      const apiResult = await LeadFlowApi.createNewLead(
        newState,
        setNewDataLayer,
        router,
        false,
        true
      );
      newState.leadTransactionId = apiResult?.TransactionId;

      // The user is going to be sent to the join flow, but if they return to the lead flow, they ought to pickup at the appointment selection screen.
      newState.step = LeadFlowConstants.STEPS.scheduleAppointment;

      LeadFlowUtils.updateStoredState(newState);

      createItem(LOCAL_STORAGE_KEYS.SINGLE_FLOW_FORM_STATE, {
        formState: {
          values: {
            email: nextCorpFormDetails.contactInput["Personal Email"],
            firstName: nextCorpFormDetails.contactInput["First Name"],
            lastName: nextCorpFormDetails.contactInput["Last Name"],
            newsletter: formValues?.receiveTextAlerts,
            phone: nextCorpFormDetails.contactInput["Phone Number"],
            companyEmail: nextCorpFormDetails.contactInput["Company Email"],
          },
        },
      });

      // WEB-3052 there's an issue w/ loading styles in production builds--perhaps the latest version of Next.js has addressed...
      // router.push(`/join/step1/${formValues?.selectedClub.facilityId}`);
      window.location.href = `/join/step1/${formValues?.selectedClub.facilityId}`;

      // since we're redirecting, no need to decrementActivityCount...
      // decrementActivityCount();
    }
  };

  /**
   *
   */
  const handleValidEmailCode = async () => {
    const nextCorpFormDetails = {
      ...corpFormDetails,
    };

    if (membershipPlans?.length === 0) {
      const nextCorpFormDetailsWithPlans = await getMembershipPlansInfo(
        nextCorpFormDetails?.selectedFacility,
        nextCorpFormDetails.contactInput["Company Email"],
        nextCorpFormDetails?.corporateAccountId,
        nextCorpFormDetails
      );

      setCorpFormDetails(nextCorpFormDetailsWithPlans);
      updateStoredState({
        ...nextCorpFormDetailsWithPlans,
        step: STEPS.chooseMembershipPlan,
      });
    } else {
      setCorpFormDetails(nextCorpFormDetails);
      updateStoredState({
        ...nextCorpFormDetails,
        step: STEPS.chooseMembershipPlan,
      });
    }

    setCurrentStep(STEPS.chooseMembershipPlan);
  };

  /**
   *
   */
  const sendAcdlInfo = useCallback(
    (info) => {
      if (!acdlInfo) {
        setAcdlInfo(info);
        setNewDataLayer(info);
      }
    },
    [acdlInfo, setNewDataLayer]
  );

  /**
   *
   */
  useEffect(() => {
    const restoreMembershipPlans = async () => {
      const nextCorpFormDetails = await getMembershipPlansInfo(
        corpFormDetails.selectedFacility,
        corpFormDetails.contactInput["Company Email"],
        corpFormDetails.corporateAccountId,
        corpFormDetails
      );

      setCorpFormDetails(nextCorpFormDetails);
      updateStoredState(nextCorpFormDetails);
      if (
        !nextCorpFormDetails.selectedMembershipPlan &&
        corpFormDetails.step !== STEPS.confirmation
      ) {
        LeadFlowUtils.updateStoredState({
          ...corpFormDetails,
          step: LeadFlowConstants.STEPS.scheduleAppointment,
        });
        router.push("/inquiry/schedule-your-visit");
      }
    };

    if (
      currentStep === STEPS.chooseMembershipPlan &&
      membershipPlans?.length === 0
    ) {
      restoreMembershipPlans();
    }

    if (
      currentStep === STEPS.scheduleAppointment &&
      visitAppointments?.length === 0
    ) {
      getVisitAppointments(corpFormDetails.selectedFacility?.facilityId);
    }
  }, [
    corpFormDetails,
    currentStep,
    getMembershipPlansInfo,
    getVisitAppointments,
    membershipPlans?.length,
    visitAppointments?.length,
    router,
  ]);

  /**
   *
   */
  useEffect(() => {
    const checkToken = async () => {
      const confirmationToken = cookies["confirmationToken"];

      if (confirmationToken) {
        const { success, barcode, productToken, transactionNumber, memberID } =
          await ProductTokenApiClient.getProductDetails(confirmationToken);

        const { productBase } = productToken;

        if (success) {
          const mappedDetails = {
            barcode,
            buyerInfo: {
              firstName: productBase?.buyer?.firstName,
              lastName: productBase?.buyer?.lastName,
              email: productBase?.buyer?.email,
            },
            memberID,
            transactionNumber,
          };

          setMembershipDetails(mappedDetails);

          const ACDLInfo = {
            ...ACDL_JOIN_FLOW_TRACKING.joinFlowConfirmation(
              router.asPath,
              barcode
            ),
            user: {
              loginStatus: "not-authenticated",
            },
          };

          sendAcdlInfo(ACDLInfo);
        }
      } else if (currentStep === STEPS.confirmation) {
        if (cookies[COOKIES.PAYMENT_JOIN_PRODUCT_TOKEN.name]) {
          removeCookie(COOKIES.PAYMENT_JOIN_PRODUCT_TOKEN.name);
          setCurrentStep(STEPS.chooseMembershipPlan);
        } else {
          setCurrentStep(STEPS.contactInformation);
        }
      }
    };

    checkToken();
  }, [cookies, currentStep, removeCookie, router, sendAcdlInfo]);

  return (
    <div className={styles.corporateFlow}>
      <ClubSelectorPanel
        isOpen={showClubSelector}
        onChangeClub={(club) => {
          setShowClubSelector(false);
          handleSelectedClub(club);
        }}
        onClose={() => {
          setShowClubSelector(false);
        }}
      />

      {headlineData && (
        <Headline
          className={styles.headline}
          finePrint={headlineData?.fields.finePrint}
          photo={headlineData?.fields.image}
          subcopy={headlineData?.fields.subcopy}
          title={headlineDataTitle}
        />
      )}

      <StyledEngineProvider injectFirst>
        {currentStep !== STEPS.confirmation && (
          <div className={styles.formContent}>
            <div className={styles.formContentInner}>
              {isActive && <Loader hasBackgroundShade isOverlay />}

              {currentStep === STEPS.contactInformation && (
                <CorpJoinForm
                  clubIsInUK={
                    selectedClub?.country?.toLowerCase() ===
                    COUNTRIES.UNITED_KINGDOM.abbreviation.toLowerCase()
                  }
                  config={config}
                  errorMessage={errorMessage}
                  onSelectClubClick={handleClubEdit}
                  onSubmit={handleSubmitCorpJoinForm}
                  selectedClub={selectedClub}
                  setNewDataLayer={setNewDataLayer}
                />
              )}

              {currentStep === STEPS.emailCodeConfirmation && (
                <EmailCodeConfirmation
                  companyEmail={corpFormDetails.contactInput["Company Email"]}
                  companyName={corpFormDetails.companyName}
                  config={config}
                  firstName={corpFormDetails.contactInput["First Name"]}
                  isLoading={isActive}
                  onValidCode={handleValidEmailCode}
                  setIsLoading={(isLoading) => {
                    isLoading
                      ? incrementActivityCount()
                      : decrementActivityCount();
                  }}
                  setNewDataLayer={setNewDataLayer}
                />
              )}

              {currentStep === STEPS.chooseMembershipPlan && (
                <ChooseMembershipPlan
                  companyName={corpFormDetails.companyName}
                  onChangeClub={handleClubEdit}
                  onShowScheduleVisit={handleShowScheduleVisit}
                  onSubmit={handleChooseMembershipSubmit}
                  plans={membershipPlans}
                  selectedFacility={corpFormDetails.selectedFacility}
                />
              )}

              {currentStep === STEPS.scheduleAppointment && (
                <ScheduleAppointment
                  appointment={selectedAppointment}
                  appointmentType="Visit"
                  appointments={visitAppointments}
                  clubName={corpFormDetails.selectedFacility?.webName}
                  onSchedule={handleScheduleAppointment}
                  onSkip={handleSkipAppointment}
                />
              )}

              {currentStep === STEPS.appointmentConfirmation && (
                <>
                  <AppointmentConfirmation
                    appointment={selectedAppointment}
                    appointmentType="Visit"
                    facility={corpFormDetails.selectedFacility}
                    onEditAppointment={handleEditAppointment}
                  />

                  <PersonalizeQuiz
                    onCtaClick={handleQuizCtaClick}
                    onItemClick={handleQuizItemClick}
                    personalizeOptions={corpFormDetails.personalizeOptions}
                    personalizeOptionsSelectedPerStep={
                      corpFormDetails.personalizeOptionsSelectedPerStep
                    }
                    personalizeOptionsStep={
                      corpFormDetails.personalizeOptionsStep
                    }
                    setNewDataLayer={setNewDataLayer}
                  />
                </>
              )}
            </div>
          </div>
        )}

        {currentStep === STEPS.confirmation && (
          <Confirmation
            data={confirmationStep}
            membershipDetails={membershipDetails}
            selectedClubDetails={corpFormDetails.selectedFacility}
            selectedPlanId={corpFormDetails?.selectedMembershipPlan?.planId}
          />
        )}
      </StyledEngineProvider>

      <ExitFlowConfirmation
        defaultExitPath="/"
        description="Your progress will not be saved."
      />
    </div>
  );
};

CorpJoinFlow.propTypes = {
  config: PropTypes.object,
  confirmationStep: PropTypes.object,
  data: PropTypes.object,
};

export default CorpJoinFlow;
